.custom-tabs-container {
    width: 100%;
  }
  
  .custom-tab-list {
    display: flex;
    width: 100%;
  }
  
  .custom-tab-list-item {
    flex-grow: 1;
    text-align: center;
  }